import React, { Suspense, useState } from 'react'
import get from 'lodash.get'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import { Box, Flex, Heading, Container } from '../../components/UI'
import styled from 'styled-components'

import FullScreenSlider from '../../components/FullScreenSlider'
import { useSpring, config } from 'react-spring'
import ContentfulEdit from '../../components/ContentfulEdit'
import BreadCrumbs from '../../components/BreadCrumbs'
import { Loader } from '../../components/Loader'

const LazyProduct = React.lazy(() => import('./Product'))

const SingleProduct = ({ data: { contentfulProduct: product } }) => {
  const isSSR = typeof window === 'undefined'
  if (!product.stripeProduct) return null

  const [imagePropsInverse, imageSetInverse] = useSpring(() => ({
    xy: [-25, -25],
    opacity: 0,
    config: config.slow,
  }))

  const SeoImg =
    get(product, 'slider.images[0].childImageSharp.sizes.src') ||
    get(product, 'slider.images[0].fluid.src')
  const [imageExpanded, setExpandImage] = useState()

  const closeSlider = () => {
    setExpandImage(false)
    imageSetInverse({
      xy: !imageExpanded ? [0, 0] : [-25, -25],
      opacity: !imageExpanded ? 1 : 0,
    })
  }
  const openSlider = () => {
    setExpandImage(true)
    imageSetInverse({
      xy: !imageExpanded ? [0, 0] : [-25, -25],
      opacity: !imageExpanded ? 1 : 0,
    })
  }

  const breadCrumbs = [
    { to: '/shop', title: 'Shop' },
    { title: product.stripeProduct.name },
  ]
  return (
    <div>
      <SEO
        title={product.stripeProduct.name}
        description={product.stripeProduct.description}
        image={SeoImg}
        pathname={`product/${product.slug}`}
        article
      />
      <Layout>
        {imageExpanded && product.slider && (
          <FullScreenSlider
            animatedStyle={{
              transform: imagePropsInverse.xy.to(
                (x, y) => `translate3d(${x}px, ${y}px, 0)`
              ),
              opacity: imagePropsInverse.opacity,
            }}
            onClose={closeSlider}
            images={product.slider.images}
            currentImageIndex={0}
          />
        )}
        <Container py={{ _: 2, md: 4 }} px={{ _: 2, md: 3 }}>
          <Flex
            flexDirection={'column'}
            itemScope
            itemType="http://schema.org/IndividualProduct"
          >
            <Box as="article" px={{ _: 0, sm: 0 }}>
              <Box as="header">
                <Box>
                  <BreadCrumbs links={breadCrumbs} />
                </Box>
                <meta itemProp="image" content={SeoImg} />

                <ContentfulEdit id={product.contentful_id} />

                <Heading
                  rel="bookmark"
                  itemProp="name"
                  as="h1"
                  fontFamily="sans"
                  pt={1}
                  mb={2}
                  color="primary.0"
                  fontSize={{ xs: 4, md: 6, lg: 7 }}
                  fontWeight={900}
                >
                  {product.stripeProduct.name}
                </Heading>
              </Box>
              {!isSSR && (
                <Suspense
                  fallback={
                    <Box
                      style={{ position: 'relative' }}
                      height={200}
                      width={1}
                      bg={'lightergrey'}
                      borderRadius={1}
                    >
                      <Loader show />
                    </Box>
                  }
                >
                  <LazyProduct product={product} openSlider={openSlider} />
                </Suspense>
              )}
            </Box>
          </Flex>
        </Container>
      </Layout>
    </div>
  )
}

export default SingleProduct

export const shopPageQuery = graphql`
  query singleProductQuery($id: String!) {
    contentfulProduct(id: { eq: $id }) {
      title
      slug
      contentful_id
      stripeProductId
      stripeProduct {
        id
        name
        description
      }
      stripePrices {
        id
        active
        currency
        unit_amount
      }
      slider {
        images {
          id
          fixed(width: 110, height: 110) {
            ...GatsbyContentfulFixed
          }
          fluid(maxWidth: 1200) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
    }
  }
`
